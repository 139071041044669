import { memo } from 'react'

import { Box, Flex } from '@inspectornexus/components'
import { BookOpen, Shield } from '@inspectornexus/icons'

import { Section2FeatureCard } from './Section2FeatureCard'

export const Section2FeaturesCard = memo(() => (
  <Box position="relative" bg="container" as="section">
    <Flex
      display={['block', 'flex', 'flex']}
      maxWidth="1040px"
      mx="auto"
      px={4}
      width="100%"
    >
      <Section2FeatureCard
        borderRight={[null, '1px solid #f6f9fc', '1px solid #f6f9fc']}
        pr={[0, 8, 8]}
        HeaderIconComponent={BookOpen}
        header="Award-Winning Reports"
        body="Our high-definition, interactive home inspection reports are clear, concise, and look
      great on any device - desktop, tablet, or mobile. The report summaries and PDFs are easy
      to navigate and download, and real estate agents absolutely love our intuitive Repair
      Request Builder. Deliver reports that go above and beyond with Inspector
      Nexus."
        linkText="View Sample Reports"
        linkUrl="/sampleReports"
      />
      <Section2FeatureCard
        borderLeft={[null, '1px solid #f6f9fc', '1px solid #f6f9fc']}
        pl={[0, 8, 8]}
        HeaderIconComponent={Shield}
        header="5-Star Support"
        body="Our responsive, professional support team is dedicated to answering your questions and
      concerns with speed and professionalism. Don't be left out in the field with a subpar
      support team again- Inspector Nexus has you covered."
        linkText="Contact Us"
        linkUrl="mailto:support@inspectornexus.com"
      />
    </Flex>
  </Box>
))

Section2FeaturesCard.displayName = 'Section2FeaturesCard'
