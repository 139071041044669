import { memo } from 'react'

import { NextImage } from 'components/NextImage'

import { Box, Flex } from '@inspectornexus/components'

interface IProps {
  boxShadow: string
}

export const BigPhoneIllustration = memo<IProps>(({ boxShadow }) => (
  <Flex
    alignSelf="flex-end"
    width="267px"
    height="553px"
    alignItems="center"
    justifyContent="center"
    overflow="hidden"
    boxShadow={boxShadow}
    display={['none', 'none', 'flex']}
    position="relative"
    style={{ borderRadius: '38px', backgroundColor: 'rgb(36,36,36)' }}
  >
    <Box
      top="46px"
      bg="container"
      height="461px"
      width="259px"
      position="absolute"
      zIndex={0}
      borderRadius={2}
      overflow="hidden"
    />
    <NextImage
      alt="Inspector Nexus Mobile App"
      src="/images/phoneBig.png"
      height="461px"
      width="259px"
    />
  </Flex>
))

BigPhoneIllustration.displayName = 'BigPhoneIllustration'
