import { memo } from 'react'

import { NextImage } from 'components/NextImage'

import type { IBoxProps, IFlexProps } from '@inspectornexus/components'
import { Box, Flex, Text } from '@inspectornexus/components'
import { Star } from '@inspectornexus/icons'

export interface IReview {
  avatar?: string
  body: string
  name: string
  time: string
}

interface IProps extends IFlexProps, IBoxProps, IReview {}

export const Section2ReviewCard = memo<IProps>(
  ({ body, name, time, avatar, ...rest }) => {
    const renderStar = (_: unknown, index: number) => (
      <Star
        key={`${name}-review-star-${index}`}
        size={16}
        color="accent3"
        style={{ fill: 'currentcolor' }}
      />
    )
    return (
      <Flex
        bg="container"
        width="90%"
        maxWidth={[null, '600px', '800px']}
        p={3}
        boxShadow="rgba(0, 0, 0, 0.05) 0px 1px 2px 0px"
        borderRadius={2}
        flexDirection={['column', 'row', 'row']}
        overflow="hidden"
        {...rest}
      >
        <Flex
          flex="1 1 auto"
          ml={[0, 3, 3]}
          mr={[0, 5, 5]}
          mb={[3, 0, 0]}
          maxWidth={['100px', '48px', '48px']}
          justifyContent="center"
          alignItems={['center', 'flex-start', 'flex-start']}
        >
          <Box
            overflow="hidden"
            boxShadow="0 1px 2px #8d8d8d"
            height="48px"
            width="48px"
            minHeight="48px"
            minWidth="48px"
            borderRadius="50%"
            position="relative"
          >
            {avatar !== undefined ? (
              <NextImage
                alt={avatar}
                src={avatar}
                style={{ zIndex: 1 }}
                width="48px"
                height="48px"
              />
            ) : null}
            <Flex
              position="absolute"
              justifyContent="center"
              alignItems="center"
              height="48px"
              width="48px"
              style={{ backgroundColor: 'rgb(126,87,194)' }}
              zIndex={0}
            >
              <Text fontSize={4} color="light">
                {name[0]}
              </Text>
            </Flex>
          </Box>
        </Flex>
        <Flex overflow="hidden" maxWidth="100%" flexDirection="column">
          <Text
            maxWidth="110px"
            color="overlay"
            fontWeight={700}
            fontSize={1}
            overflow="hidden"
            style={{ textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}
          >
            {name}
          </Text>
          <Text
            color="text"
            mt={1}
            style={{ transform: 'translateX(1px)', fontSize: '11px' }}
          >
            {time}
          </Text>
          <Flex mt={2}>
            {Array.from({ length: 5 }).fill(undefined).map(renderStar)}
          </Flex>
          <Text color="overlay" mt={1} style={{ fontSize: '13px' }}>
            {body}
          </Text>
        </Flex>
      </Flex>
    )
  }
)

Section2ReviewCard.displayName = 'Section2ReviewCard'
