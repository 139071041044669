import { memo } from 'react'

import { Link } from 'components/Link'

import { Flex, Heading, Text } from '@inspectornexus/components'
import { ChevronRight, Clipboard } from '@inspectornexus/icons'

export const Section2Top = memo(() => (
  <Flex
    display={['block', 'block', 'flex']}
    flexDirection={[null, null, 'column']}
    height={[null, null, '580px']}
    justifyContent={[null, null, 'center']}
    bottom={[null, null, '30px']}
    as="section"
    position={[null, null, 'relative']}
    mx="auto"
    px={3}
    maxWidth="1040px"
    width={1}
  >
    <Heading
      fontWeight={600}
      letterSpacing=".025em"
      fontSize={4}
      lineHeight="33px"
      as="h2"
      color="accent3"
      textTransform="uppercase"
      width={[null, null, '50%']}
      my={0}
      maxWidth={['none', '376px', 'none']}
    >
      <Flex
        width="100%"
        display={['block', 'block', 'flex']}
        alignItems={[null, null, 'center']}
        justifyContent={[null, null, 'flex-end']}
        mb={[3, 3, null]}
      >
        <Flex
          flex={[null, null, 'none']}
          order={[null, null, 1]}
          justifyContent="center"
          alignItems="center"
          bg="accent3"
          borderRadius="50%"
          height="66px"
          width="66px"
          mx={['auto', 'auto', null]}
          ml={[null, null, 3]}
          mb={[3, 3, 0]}
        >
          <Clipboard size={34} color="light" />
        </Flex>
        <Text
          color="accent3"
          flex={[null, null, '1']}
          textAlign={['center', 'center', 'right']}
        >
          Finish Your reports on site
        </Text>
      </Flex>
    </Heading>
    <Text
      fontSize={2}
      textAlign={[null, 'right']}
      as="p"
      width={[null, '50%']}
      pt={[5, 0, 0]}
      color="text"
      lineHeight="28px"
    >
      Publish and send your report with a single tap before you leave the
      property. Absolutely no internet connection is required until you finish
      the inspection.
    </Text>
    <Link
      fontSize={[2, 2, 3]}
      mb={[10, 9, 0]}
      id="section2Link"
      flex="none"
      display="block"
      href="https://web.inspectornexus.com/web"
      pt={5}
      mt={[0, 5]}
      borderTop={[null, '2px solid #f1f7fb']}
      width={[null, '50%']}
      textAlign={[null, 'right']}
    >
      Create your free account today, no credit card required
      <ChevronRight
        size={14}
        color="brand"
        style={{ display: 'inline-block', transform: 'translateY(2px)' }}
      />
    </Link>
  </Flex>
))

Section2Top.displayName = 'Section2Top'
