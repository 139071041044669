import { memo } from 'react'

import styled from '@emotion/styled'
import { Box } from '@inspectornexus/components'

import { Section2FeaturesCard } from './Section2FeaturesCard'
import { Section2ReviewsCard } from './Section2ReviewsCard'

const Container = styled(Box)`
  &::before {
    content: '';
    position: absolute;
    z-index: 0;
    width: 100%;
    height: 180%;
    top: 0;
    transform: skewY(-12deg);
    background: #f6f9fc;
  }
`

export const Section2Bottom = memo(() => (
  <Container bg="transparent" pt={8} position="relative" as="section">
    <Section2ReviewsCard />
    <Section2FeaturesCard />
  </Container>
))

Section2Bottom.displayName = 'Section2Bottom'
