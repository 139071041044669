import { memo } from 'react'

import { Link } from 'components/Link'

import { Flex, Pill, Text } from '@inspectornexus/components'
import { ChevronRight } from '@inspectornexus/icons'

export const Section1Announcement = memo(() => (
  <Flex
    alignItems="center"
    width={['100vw', 'calc(100% + 120px)']}
    maxWidth={['auto', '1160px']}
    minWidth="400px"
    ml={['-24px', '-24px', '-16px'] as unknown as number[]}
    height="80px"
    mb={3}
  >
    <Flex width="100%" style={{ backgroundColor: 'rgba(15, 15, 120,0.08)' }}>
      <Link
        href="https://web.inspectornexus.com/web"
        color="lightText"
        display="inline"
        fontSize={1}
        fontWeight={500}
        lineHeight={['17px', '26px']}
      >
        <Flex
          py={[3, 2]}
          pl={[4, 4, 3]}
          pr={[3, 2]}
          justifyContent="flex-start"
          alignItems="center"
          width="100%"
        >
          <Pill
            bg="accent3"
            borderRadius={3}
            display="block"
            lineHeight="17px"
            textTransform="uppercase"
          >
            New
          </Pill>
          <Text fontSize={1} mx={[3, 2]} as="span" color="lightText">
            Import your library from HIP, HomeGauge, and more
          </Text>
          <ChevronRight
            size={14}
            color="light"
            style={{ transform: 'translateY(1px)' }}
          />
        </Flex>
      </Link>
    </Flex>
  </Flex>
))

Section1Announcement.displayName = 'Section1Announcement'
