import { memo } from 'react'

import { Heading, Text } from '@inspectornexus/components'

export const Section1Content = memo(() => (
  <>
    <Heading fontWeight={400} fontSize={[6, 6, 7]} as="h1" color="lightText">
      The most advanced home inspection software on the market.
    </Heading>
    <Text
      as="p"
      maxWidth={['500px', '60%', '50%']}
      mt={4}
      lineHeight="28px"
      color="lightText2"
    >
      Inspector Nexus makes it dead-simple to produce world-class inspection
      reports that your clients and agents will love, at a fraction of the
      price.
    </Text>
  </>
))

Section1Content.displayName = 'Section1Content'
