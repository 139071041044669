import { memo } from 'react'

import { Box, Flex, Text } from '@inspectornexus/components'

import { Section1Announcement } from './Section1Announcement'
import { Section1Buttons } from './Section1Buttons'
import { Section1Content } from './Section1Content'

interface IProps {
  headerHeight: number
}

export const Section1 = memo<IProps>(({ headerHeight }) => (
  <Flex
    top={[0, headerHeight + 24]}
    alignItems={[null, 'flex-start']}
    as="section"
    position="relative"
    display={[null, 'flex']}
    height={[null, '600px', '760px']}
  >
    <Box
      maxWidth="1040px"
      mb={0}
      mx="auto"
      pb={[8, 0]}
      pt={[7, 0]}
      px={4}
      width="100%"
    >
      <Section1Announcement />
      <Section1Content />
      <Section1Buttons />
      <Text
        fontSize={1}
        as="p"
        maxWidth={['500px', '60%', '50%']}
        my={3}
        color="lightText2"
      >
        3 risk-free inspection reports included with new accounts. No credit
        card required.
      </Text>
    </Box>
  </Flex>
))

Section1.displayName = 'Section1'
