import { memo } from 'react'

import { NextImage } from 'components/NextImage'

import { Box, Flex } from '@inspectornexus/components'

interface IProps {
  boxShadow: string
}

export const SmallPhoneIllustration = memo<IProps>(({ boxShadow }) => (
  <Flex
    width="225px"
    height="467px"
    alignItems="center"
    justifyContent="center"
    boxShadow={boxShadow}
    order={[-1, -1, 0]}
    mt={[null, 0, 7]}
    mr={[null, 0, 7]}
    mb={[null, 7, 0]}
    ml={[null, 9, 0]}
    position="relative"
    style={{ borderRadius: '38px', backgroundColor: 'rgb(36,36,36)' }}
  >
    <Box
      top="46px"
      bg="container"
      height="375px"
      width="211px"
      position="absolute"
      zIndex={0}
      borderRadius={2}
      overflow="hidden"
    />
    <NextImage
      alt="Inspector Nexus Mobile App"
      src="/images/phoneSmall.png"
      height="375px"
      width="211px"
    />
  </Flex>
))

SmallPhoneIllustration.displayName = 'SmallPhoneIllustration'
