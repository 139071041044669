import { memo } from 'react'

import type { ITextProps } from '@inspectornexus/components'
import { Text } from '@inspectornexus/components'
import type { ITheme } from '@inspectornexus/theme'

interface IProps {
  idx: 0 | 1 | 2 | 3 | 4
}

const COLOR_MAP = {
  sm: ['#134D69', '#68c0bb', '#2790c8', 'transparent', 'transparent'],
  med: ['#134D69', '#0866a8', '#0A5B94', 'transparent', 'transparent'],
  lg: ['#0e485c', '#104E62', '#104F63', '#046B83', '#085388']
} as const

const getIndexBg = (index: 0 | 1 | 2 | 3 | 4) =>
  [COLOR_MAP.sm[index], COLOR_MAP.med[index], COLOR_MAP.lg[index]] as Array<
    keyof ITheme['colors']
  >

const getStripeProps = (index: 0 | 1 | 2 | 3 | 4) => {
  const sharedProps: Partial<ITextProps> = {
    bg: getIndexBg(index),
    height: ['40px', '150px', '190px'],
    position: 'absolute'
  }
  switch (index) {
    case 0:
      return {
        ...sharedProps,
        left: ['50%', null, '-16.66666%'],
        top: ['40px', 0, null],
        width: ['50%', null, '33.33333%']
      }
    case 1:
      return {
        ...sharedProps,
        bottom: ['40px', 'auto', null],
        left: [null, null, '16.66666%'],
        right: [0, null, 'auto'],
        top: [null, '300px', 0],
        width: ['14%', '16.66667%', '33.33333%']
      }
    case 2:
      return {
        ...sharedProps,
        bottom: [0, null, 'auto'],
        left: [null, null, '49.99999%'],
        right: [0, 'auto', null],
        top: [null, null, null],
        width: ['25%', '33.33333%', '33.33333%']
      }
    case 3:
      return {
        ...sharedProps,
        display: ['none', 'none', 'block'],
        right: [null, null, '-16.66666%'],
        top: [null, null, '380px'],
        width: [null, null, '33.33333%']
      }
    case 4:
      return {
        ...sharedProps,
        display: ['none', 'none', 'block'],
        bottom: [null, null, 0],
        width: [null, null, '33.33333%']
      }

    default:
      return sharedProps
  }
}

export const Stripe = memo<IProps>(({ idx }) => (
  <Text as="span" {...getStripeProps(idx)} />
))
Stripe.displayName = 'Stripe'
