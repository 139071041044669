import { memo } from 'react'

import { NextImage } from 'components/NextImage'

import { Box, Flex } from '@inspectornexus/components'

interface IProps {
  boxShadow: string
}

export const TabletPortraitIllustration = memo<IProps>(({ boxShadow }) => (
  <Flex
    display={['flex', 'none', 'flex']}
    alignSelf="flex-end"
    width="450px"
    height="675px"
    mt={[9, null, 7]}
    alignItems="center"
    justifyContent="center"
    boxShadow={boxShadow}
    position="relative"
    style={{ borderRadius: '38px', backgroundColor: 'rgb(36,36,36)' }}
  >
    <Box
      top="46px"
      bg="container"
      height="583px"
      width="437px"
      position="absolute"
      zIndex={0}
      borderRadius={2}
      overflow="hidden"
    />
    <NextImage
      alt="Inspector Nexus Mobile App"
      src="/images/tabletPortrait.png"
      height="583px"
      width="437px"
    />
  </Flex>
))

TabletPortraitIllustration.displayName = 'TabletPortraitIllustration'
