import { memo } from 'react'

import { NextImage } from 'components/NextImage'

import { Box, Flex } from '@inspectornexus/components'

interface IProps {
  boxShadow: string
}

export const TabletLandscapeIllustration = memo<IProps>(({ boxShadow }) => (
  <Flex
    alignSelf={[null, 'flex-end']}
    width="512px"
    height="352px"
    mr={7}
    ml={[7, 0]}
    mt={[9, 0]}
    alignItems="center"
    justifyContent="center"
    boxShadow={boxShadow}
    position="relative"
    style={{ borderRadius: '38px', backgroundColor: 'rgb(36,36,36)' }}
  >
    <Box
      top="18px"
      bg="container"
      height="315px"
      width="420px"
      position="absolute"
      zIndex={0}
      borderRadius={2}
      overflow="hidden"
    />
    <NextImage
      alt="Inspector Nexus Mobile App"
      src="/images/tabletLandscape.png"
      height="315px"
      width="420px"
    />
  </Flex>
))

TabletLandscapeIllustration.displayName = 'TabletLandscapeIllustration'
