import { memo } from 'react'

import styled from '@emotion/styled'
import { Box } from '@inspectornexus/components'

import { Stripe } from './Stripe'

const Container = styled(Box)`
  background: linear-gradient(150deg, #0e485c 15%, #0866a8 70%, #2790c8 94%);
  transform: skewY(-12deg);
  transform-origin: 0;
  @media (min-width: 2190px) {
    transform-origin: 500px;
  }
`

const renderStripe = (_: unknown, index: number) => (
  <Stripe key={String(index)} idx={index as 0 | 1 | 2 | 3 | 4} />
)

export const Stripes = memo(() => (
  <Container height="100%" overflow="hidden" position="absolute" width="100%">
    {Array.from({ length: 5 }).fill(undefined).map(renderStripe)}
  </Container>
))

Stripes.displayName = 'Stripes'
