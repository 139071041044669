import type { ComponentType } from 'react'
import { memo } from 'react'

import { Link } from 'components/Link'

import type { IBoxProps } from '@inspectornexus/components'
import { Box, Flex, Heading, Text } from '@inspectornexus/components'
import type { IconProps } from '@inspectornexus/icons'
import { ChevronRight } from '@inspectornexus/icons'

interface IProps extends IBoxProps {
  HeaderIconComponent: ComponentType<IconProps>
  body: string
  header: string
  linkText: string
  linkUrl: string
}

export const Section2FeatureCard = memo<IProps>(
  ({ HeaderIconComponent, header, body, linkText, linkUrl, ...rest }) => (
    <Box as="section" py={8} flex="1 1 auto" {...rest}>
      <Heading
        color="accent3"
        fontSize={3}
        textTransform="uppercase"
        fontWeight={600}
        lineHeight="28px"
        letterSpacing=".025em"
      >
        <Flex
          mb={4}
          justifyContent="center"
          alignItems="center"
          bg="accent3"
          borderRadius="50%"
          height="66px"
          width="66px"
        >
          <HeaderIconComponent size={34} color="light" />
        </Flex>
        {header}
      </Heading>
      <Text
        fontSize={2}
        color="text"
        lineHeight="28px"
        as="p"
        my={3}
        fontWeight={400}
      >
        {body}
      </Text>
      <Link display="block" href={linkUrl}>
        {linkText}
        <ChevronRight
          size={18}
          color="brand"
          style={{ display: 'inline', transform: 'translateY(3px)' }}
        />
      </Link>
    </Box>
  )
)

Section2FeatureCard.displayName = 'Section2FeatureCard'
