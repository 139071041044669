import { memo } from 'react'

import { Button } from 'components/Button'
import { Link } from 'components/Link'

import { Box, Flex, Text } from '@inspectornexus/components'

export const Section1Buttons = memo(() => (
  <Flex as="ul" mt={[7, 8]}>
    <Box as="li">
      <Link href="https://web.inspectornexus.com/web">
        <Button mr={4} bg="accent3" hoverBg="accent3">
          <Text
            color="inherit"
            textTransform="uppercase"
            fontSize={3}
            fontWeight={600}
          >
            Create Free Account
          </Text>
        </Button>
      </Link>
    </Box>
    <Box display={['none', 'inline-block']} as="li">
      <Link href="mailto:support@inspectornexus.com" target="_blank">
        <Button bg="container" color="brand">
          <Text
            color="inherit"
            textTransform="uppercase"
            fontSize={3}
            fontWeight={600}
          >
            Contact Us
          </Text>
        </Button>
      </Link>
    </Box>
  </Flex>
))

Section1Buttons.displayName = 'Section1Buttons'
