import type { ComponentType } from 'react'

import type { ExportedImageProps } from 'next-image-export-optimizer'
import Image from 'next-image-export-optimizer'

import styled from '@emotion/styled'

export const NextImage: ComponentType<ExportedImageProps> = styled(Image)`
  border-radius: 5px;
  z-index: 0;
`
