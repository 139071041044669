import { memo } from 'react'

import { Flex, Heading, Text } from '@inspectornexus/components'
import { Star } from '@inspectornexus/icons'

import type { IReview } from './Section2ReviewCard'
import { Section2ReviewCard } from './Section2ReviewCard'

const reviews: IReview[] = [
  {
    time: '2:14 PM',
    avatar: '/images/review1Avatar.png',
    name: 'Donald Lebsack',
    body: 'Simply the best user experience as an inspector. Very easy, simple to navigate, and delivers the best most user friendly report on the market today. Hands down the best support in all of IT.'
  },
  {
    time: '8:00 AM',
    avatar: '/images/review2Avatar.png',
    name: 'Timothy C. Self',
    body: 'Extremely powerful, customizable and modern reporting platform. Customer service is top notch too!'
  },
  {
    time: '6:23 PM',
    name: 'Corey M. Beard',
    body: 'This software is by far the best in the industry. The reports BLOW the minds off of agents and clients. It is so easy to use and flows very well. It saves me over 2 hours a day. Amazing support handled all of my issues with speed and professionalism.'
  }
]

const renderReview = (review: IReview, index: number) => (
  <Section2ReviewCard
    key={`review-${index}`}
    mt={index > 0 ? 3 : 0}
    {...review}
  />
)

export const Section2ReviewsCard = memo(() => (
  <>
    <Heading
      position="relative"
      fontWeight={600}
      letterSpacing=".025em"
      fontSize={4}
      lineHeight="33px"
      as="h2"
      color="accent3"
      textTransform="uppercase"
      textAlign="center"
    >
      <Flex
        mx="auto"
        mb={5}
        justifyContent="center"
        alignItems="center"
        bg="accent3"
        borderRadius="50%"
        height="66px"
        width="66px"
      >
        <Star size={34} color="light" style={{ fill: 'currentcolor' }} />
      </Flex>
      <Text as="span" flex="1" color="accent3">
        What inspectors are saying
      </Text>
    </Heading>
    <Flex
      mt={5}
      pb={8}
      width="100%"
      position="relative"
      flexDirection="column"
      alignItems="center"
    >
      {reviews.map(renderReview)}
    </Flex>
  </>
))

Section2ReviewsCard.displayName = 'Section2ReviewsCard'
