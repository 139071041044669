import { memo } from 'react'

import { Box } from '@inspectornexus/components'

import { Illustrations } from './Illustrations'
import { Section1 } from './Section1'
import { Section2 } from './Section2'
import { Stripes } from './Stripes'

// ts-prune-ignore-next
export interface IProps {
  headerHeight: number
}

export const Home = memo<IProps>(({ headerHeight }) => (
  <>
    <Box as="header" position="relative">
      <Stripes />
      <Section1 headerHeight={headerHeight} />
    </Box>
    <Illustrations />
    <Section2 />
  </>
))

Home.displayName = 'Home'
