import { memo, useEffect, useState } from 'react'

import styled from '@emotion/styled'
import { Flex } from '@inspectornexus/components'

import {
  BigPhoneIllustration,
  SmallPhoneIllustration
} from './PhoneIllustrations'
import {
  TabletLandscapeIllustration,
  TabletPortraitIllustration
} from './TabletIllustrations'

const illustrationShadow =
  '#242424 0px 4px 7px 1px inset, rgba(50, 57, 66, 0.25) 0px -5px 20px inset, rgba(16, 32, 64, 0.14) 0px 2px 6px, rgba(16, 32, 64, 0.05) 0px 10px 20px;'

const Container = styled(Flex)`
  transform: scale(0.5) rotate(-12deg) translateX(50px);
  transform-origin: 50% 20%;
  pointer-events: none;
  ${({ theme }) => `
    @media (min-width:${theme.breakpoints[0] ?? ''}) {
        transform: rotate(-12deg);
        transform-origin: 100% 0;
    }
  `};
`

export const Illustrations = memo(() => {
  const [mobileTopOffset, handleSetMobileTopOffset] = useState(815)

  const handleSetStyleIfMobile = () => {
    if (typeof window === 'undefined' || typeof document === 'undefined') {
      return
    }

    if (window.innerWidth > 690) {
      return
    }
    const section2Link = document.getElementById('section2Link')
    if (!section2Link) {
      return
    }
    handleSetMobileTopOffset(section2Link.offsetTop - 25)
  }

  const handleStartListeners = () => {
    if (typeof window === 'undefined') {
      return
    }
    handleSetStyleIfMobile()
    window.addEventListener('resize', handleSetStyleIfMobile)
  }
  const handleDisposeListeners = () => {
    if (typeof window === 'undefined') {
      return
    }
    window.removeEventListener('resize', handleSetStyleIfMobile)
  }

  useEffect(() => {
    handleStartListeners()
    return handleDisposeListeners
  }, [])
  return (
    <Container
      as="section"
      ml={['-644px', '-50px', '-10px'] as unknown as number[]}
      position="absolute"
      top={[`${mobileTopOffset}px`, '215px', '20px']}
      width={['1287px', '512px', '829px']}
      left="50%"
      flexWrap={[null, 'wrap']}
      zIndex={1}
    >
      <TabletLandscapeIllustration boxShadow={illustrationShadow} />
      <BigPhoneIllustration boxShadow={illustrationShadow} />
      <SmallPhoneIllustration boxShadow={illustrationShadow} />
      <TabletPortraitIllustration boxShadow={illustrationShadow} />
    </Container>
  )
})

Illustrations.displayName = 'Illustrations'
