import { memo } from 'react'

import { Box } from '@inspectornexus/components'

import { Section2Bottom } from './Section2Bottom'
import { Section2Top } from './Section2Top'

export const Section2 = memo(() => (
  <Box as="section">
    <Section2Top />
    <Section2Bottom />
  </Box>
))

Section2.displayName = 'Section2'
